$color-base-white: #fff;
$color-base-50: #f8fafc;
$color-base-100: #f1f5f9;
$color-base-200: #e2e8f0;
$color-base-300: #cbd5e1;
$color-base-400: #94a3b8;
$color-base-500: #64748b;
$color-base-600: #475569;
$color-base-700: #334155;
$color-base-800: #1e293b;
$color-base-900: #0f172a;
$color-success-shade: #136d34;
$color-success: #22c55e;
$color-success-tint: #51e186;
$color-error-shade: #bc1010;
$color-danger-shade: #e11d48;
$color-error: #ef4444;
$color-error-tint: #f58a8a;
$color-warning-shade: #856605;
$color-warning: #eab308;
$color-warning-tint: #f9cd44;
$color-warning-bg: #fef3c7;
$color-text: #1e293b;
$color-headings: $color-base-700;
$color-borders: #e2e8f0;
$color-placeholder-bg: #e2e8f0;
